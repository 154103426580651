import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useField } from 'react-final-form'
import SelectField from '../../molecules/fields/SelectField'
import useFieldValue from '../../../hooks/useFieldValue'
import { dataToOptions } from '../../../utils/helpers'
import useArea from '../../../hooks/useArea'
import useSearchParams from '../../../hooks/useSearchParams'
import { useAuth } from '../../molecules/Auth'
import { isSuccessful } from '../../../hooks/useQuery'

const UserTypeAreaFilter = ({ userTypeList, onChange, onFilter }) => {
  const { user } = useAuth()
  const urlParams = useSearchParams()
  const userTypeOptions = dataToOptions(userTypeList)
  const userTypeId = useFieldValue('user_type_id')
  const { input: areaIdInput } = useField('area_id')
  const [updateFilter, setUpdateFilter] = useState(false)

  const urlAreaId = urlParams.has('area_id')
  const urlUserTypeId = urlParams.has('user_type_id')
  const areaLoadRequired = useRef(urlUserTypeId && urlAreaId)

  const { useGetAreasByUserType } = useArea()
  const paramsForAreasByUserType = {
    institution_id: user.institution_id
  }
  if (user.institution_has_branch_offices) {
    paramsForAreasByUserType.branch_office_id = urlParams.get('branch_office_id') || user.branch_office_id
  }
  const currentUserTypeId = userTypeId || userTypeOptions[0].value

  const { data: areas = [], status } = useGetAreasByUserType(currentUserTypeId, paramsForAreasByUserType, {
    enabled: Boolean(currentUserTypeId),
    onSuccess: data => {
      if (!areaLoadRequired.current) {
        onFilter({ area_id: data[0].id, user_type_id: currentUserTypeId }, false)
        onChange(true, { areaId: data[0].id, userTypeId: currentUserTypeId })
        areaIdInput.onChange(data[0].id)
      } else {
        onChange(true, { areaId: data[0].id })
      }
      areaLoadRequired.current = false
      setUpdateFilter(true)
    }
  })

  return (
    <div className="mx-6 md:mx-0 w-full">
      <p>Selecciona el perfil y el área para ver sus solicitudes correspondientes.</p>
      <div className="flex flex-row flex-wrap sm:gap-4">
        <SelectField
          defaultValue={userTypeOptions[0].value}
          className="mb-4 min-w-60"
          containerClassName="sm:mb-1.5 mb-0"
          margin="none"
          options={userTypeOptions}
          name="user_type_id"
          onChange={value => onChange(false, { userTypeId: value, areaId: null })}
          emptyLabel="Selecciona un actor"
        />
        {isSuccessful(status) && updateFilter && (
          <SelectField
            className="mb-4 min-w-60"
            options={dataToOptions(areas)}
            name="area_id"
            onChange={value => onChange(false, { areaId: value })}
            emptyLabel="Selecciona una área"
          />
        )}
      </div>
    </div>
  )
}
export default UserTypeAreaFilter

UserTypeAreaFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  userTypeList: PropTypes.array
}
