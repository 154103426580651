import useCrud from './useCrud'

const urlFetch = '/admin/v1/institution_types'

const useInstitutionType = () => {
  const { get } = useCrud(urlFetch)

  const getInstitutionTypes = options => get(['institutionTypes'], {}, options)

  return { getInstitutionTypes }
}

export default useInstitutionType
