import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useInstitutionType from '../../../../hooks/useInstitutionType'
import useInstitution from '../../../../hooks/useInstitution'
import useUbigeos from '../../../../hooks/useUbigeos'
import useFieldValue from '../../../../hooks/useFieldValue'
import useUserTypeStates from '../../../../hooks/useUserTypeStates'
import { CITIZEN_ID } from '../../../../configs/procedureManagementsConfig'
import useForm from '../../../../hooks/useForm'
import { isSuccessful } from '../../../../hooks/useQuery'
import useUserPermission from '../../../../hooks/useUserPermission'

const formTypes = {
  all: 'Tipo de formulario: Todos',
  simple: 'Tipo de formulario: Formularios simples',
  procedure: 'Tipo de formulario: Form. de gestión de trámites',
  template: 'Tipo de formulario: Formularios de plantillas',
  quick_search: 'Tipo de formulario: Form. de consultas rápidas'
}

const userRoles = {
  admin: 'Tipo de usuario: Administrador',
  collaborator: 'Tipo de usuario: Colaborador',
  coordinator: 'Tipo de usuario: Coordinador de entidad',
  branch_coordinator: 'Tipo de usuario: Coordinador de sede'
}

const filterPrefixMap = {
  forms: 'form_',
  institutions: 'institution_',
  users: 'user_',
  answers: 'answer_',
  receivedRequests: 'received_request_'
}

const AppliedFilters = ({ form, selectedIndicator }) => {
  const { getInstitutionTypes } = useInstitutionType()
  const { canSeeInstitutionFilter } = useUserPermission('statistics')
  const { data: institutionTypes } = getInstitutionTypes({ enabled: canSeeInstitutionFilter, initialData: [] })
  const { getInstitutions } = useInstitution()
  const { data: institutions } = getInstitutions()
  const { data: userTypeStates } = useUserTypeStates(CITIZEN_ID)
  const formValues = form.getState().values

  const formType = formValues.received_request_form_type
  const { getAllForm } = useForm()
  const { data: formData, status: statusAllForms } = getAllForm(['all_forms', formType], { type: formType })

  const regionId = useFieldValue(`${filterPrefixMap[selectedIndicator]}region`)
  const provinceId = useFieldValue(`${filterPrefixMap[selectedIndicator]}province`)

  const { getRegions, getProvinces, getDistricts } = useUbigeos()
  const { data: regions } = getRegions()
  const { data: provinces } = getProvinces(regionId)
  const { data: districts } = getDistricts(provinceId)

  const filterNames = {
    form_type: formTypes,
    answer_form_type: formTypes,
    received_request_form_type: formTypes,
    form_user_role: userRoles,
    user_role: userRoles
  }

  const getStateValue = (formType, value) => {
    if (formType === 'simple' || formType === 'template') return value

    if (formType === 'procedure') {
      const userTypeState = userTypeStates.find(state => state.id.toString() === value)
      return userTypeState?.name || ''
    }
  }

  const renderChips = () => {
    const filterPrefix = filterPrefixMap[selectedIndicator] || ''
    const appliedFilters = Object.entries(formValues).filter(([key]) => key.startsWith(filterPrefix))

    return appliedFilters.flatMap(([filterKey, value]) => {
      if (
        filterKey === 'page' ||
        filterKey === filterPrefixMap[selectedIndicator] + 'range_from' ||
        filterKey === filterPrefixMap[selectedIndicator] + 'range_to' ||
        value === ''
      ) {
        return null
      }

      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <Chip
            key={`${filterKey}-${item}-${index}`}
            label={filterNames[filterKey] ? filterNames[filterKey][item] : item}
            onDelete={() =>
              form.change(
                filterKey,
                form.getState().values[filterKey].filter(x => x !== item)
              )
            }
            className="bg-gray-200 rounded-full text-sm font-semibold text-gray-700 mb-2 mr-2 min-w-min whitespace-nowrap"
            deleteIcon={<CloseIcon />}
          />
        ))
      } else {
        let filterName = filterNames[filterKey] ? filterNames[filterKey][value] : value

        if (filterKey === filterPrefix + 'institution_type') {
          filterName = 'Tipo de entidad: ' + institutionTypes.find(it => it.id.toString() === value)?.name || value
        } else if (filterKey === filterPrefix + 'institution_id') {
          filterName = 'Entidad: ' + institutions.find(it => it.id.toString() === value)?.name || value
        } else if (filterKey === filterPrefix + 'region') {
          filterName = 'Departamento: ' + regions.find(it => it.id.toString() === value)?.name || value
        } else if (filterKey === filterPrefix + 'province') {
          filterName = 'Provincia: ' + provinces?.find(it => it.id.toString() === value)?.name || value
        } else if (filterKey === filterPrefix + 'district') {
          filterName = 'Distrito: ' + districts?.find(it => it.id.toString() === value)?.name || value
        } else if (filterKey === filterPrefix + 'status') {
          const valueState = getStateValue(formValues.received_request_form_type, value)
          filterName = 'Estado de atención: ' + valueState?.toLowerCase()?.toUpperFirst()
        } else if (filterKey === filterPrefix + 'form_id' && isSuccessful(statusAllForms)) {
          const form = formData.data.find(el => el.id === parseInt(value))
          filterName = 'Formulario: ' + form?.name
        }

        return (
          <Chip
            key={`${filterKey}-${value}`}
            label={filterName}
            onDelete={() => form.change(filterKey, [])}
            className="bg-gray-200 rounded-full text-sm font-semibold text-gray-700 mb-2 mr-2 min-w-min whitespace-nowrap"
            deleteIcon={<CloseIcon />}
          />
        )
      }
    })
  }

  return (
    <div className="py-6">
      <div className="inline-flex mr-2">Filtros aplicados:</div>
      {renderChips()}
    </div>
  )
}

AppliedFilters.propTypes = {
  form: PropTypes.object.isRequired,
  selectedIndicator: PropTypes.string.isRequired
}

export default AppliedFilters
