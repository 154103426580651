import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useFilters from '../../../hooks/useFilters'
import PageBody from '../../molecules/PageBody'
import ProceduresFilter from '../procedure_managements/ProceduresFilter'
import ProceduresDetailsCards from './ProceduresDetailsCards'
import UserTypeFilter from '../procedure_managements/UserTypeFilter'
import { exportUrl, optionsOrderBy } from '../../../configs/enabledProcedureManagementsConfig'
import ExportSelectButton from '../exports/ExportSelectButton'
import queryString from 'query-string'

const ProcedureBody = ({ defaultFilters, userTypes = [], id, areaId, states, hasAccess }) => {
  const hasMultipleUserTypes = userTypes?.length > 1
  const [stateUpdater, setStateUpdater] = useState()
  const [stateDelay, setStateDelay] = useState(!hasMultipleUserTypes)
  const showProcedures = hasAccess ? stateDelay : true

  const exportParams = queryString.stringify({
    'exportables[0][exportable_id]': id,
    'exportables[0][exportable_type]': 'procedures',
    'exportables[1][exportable_id]': id,
    'exportables[1][exportable_type]': 'form_procedures'
  })

  const {
    onFilter,
    filters,
    initialFilters,
    results: procedures
  } = useFilters(`/admin/v1/enabled_procedure_managements/${id}/procedures.json`, defaultFilters)

  const handleChangeType = value => {
    setStateDelay(value)
  }

  useEffect(() => {
    const filtersValues = defaultFilters || initialFilters
    if (filtersValues) {
      onFilter(filtersValues, false)
      setStateUpdater(filtersValues.state)
    }
  }, [defaultFilters, initialFilters])

  return (
    <PageBody
      results={showProcedures ? procedures : { total_pages: 0, total_count: 0 }}
      onFilter={onFilter}
      initialFilters={initialFilters}
      searchPlaceHolder="Buscar por nombre, nro. de documento o código"
      asideFilter={<ProceduresFilter areaId={areaId} states={states} />}
      upperFilter={
        hasMultipleUserTypes &&
        hasAccess && (
          <div className="mx-6 md:mx-0 md:w-1/2">
            <p>Selecciona el perfil para ver sus solicitudes correspondientes:</p>
            <UserTypeFilter userTypeList={userTypes} stateUpdater={stateUpdater} onChange={handleChangeType} />
          </div>
        )
      }
      topRightSide={
        <ExportSelectButton
          mainExportable={{
            title: 'Exportar solicitudes',
            exportable: { type: 'procedures', id }
          }}
          listExportables={[
            {
              title: 'Exportar respuestas del formulario',
              shortTitle: 'Exportar respuestas del form.',
              exportable: { type: 'form_procedures', id }
            }
          ]}
          url={exportUrl(id, `?${exportParams}`)}
          filters={filters}
        />
      }
      optionsOrderBy={optionsOrderBy}
      searchContainerClassName="sm:max-w-84 md:max-w-104 mb-4 md:mb-0"
    >
      {showProcedures && <ProceduresDetailsCards procedures={procedures.data} id={id} hasAccess={hasAccess} />}
    </PageBody>
  )
}

ProcedureBody.propTypes = {
  userTypes: PropTypes.array,
  areaId: PropTypes.number,
  defaultFilters: PropTypes.object,
  id: PropTypes.string,
  states: PropTypes.array,
  hasAccess: PropTypes.bool
}

export default ProcedureBody
